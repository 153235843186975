import React from 'react';

const SquareLogoIcon = (props: any) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.989 1.247e-07H4.011C3.48431 -0.000131218 2.96276 0.103494 2.47613 0.304956C1.9895 0.506419 1.54733 0.801776 1.17486 1.17415C0.802388 1.54653 0.506921 1.98863 0.305338 2.47521C0.103754 2.96179 -1.63769e-08 3.48332 0 4.01V19.988C-3.30475e-08 21.0519 0.422555 22.0722 1.17473 22.8246C1.92691 23.5769 2.94712 23.9997 4.011 24H19.989C21.0529 23.9997 22.0731 23.5769 22.8253 22.8246C23.5774 22.0722 24 21.0519 24 19.988V4.01C24 3.48332 23.8962 2.96179 23.6947 2.47521C23.4931 1.98863 23.1976 1.54653 22.8251 1.17415C22.4527 0.801776 22.0105 0.506419 21.5239 0.304956C21.0372 0.103494 20.5157 -0.000131218 19.989 1.247e-07ZM19.636 18.371C19.636 18.7066 19.5028 19.0285 19.2656 19.2658C19.0283 19.5032 18.7066 19.6367 18.371 19.637H5.631C5.46479 19.637 5.30022 19.6042 5.14667 19.5406C4.99313 19.477 4.85364 19.3837 4.73616 19.2661C4.61868 19.1486 4.52552 19.009 4.46201 18.8554C4.39849 18.7018 4.36587 18.5372 4.366 18.371V5.63C4.36587 5.4638 4.39849 5.29919 4.46201 5.1456C4.52552 4.99201 4.61868 4.85244 4.73616 4.73487C4.85364 4.6173 4.99313 4.52402 5.14667 4.46039C5.30022 4.39675 5.46479 4.364 5.631 4.364H18.371C18.7066 4.36427 19.0283 4.49776 19.2656 4.73516C19.5028 4.97255 19.636 5.29441 19.636 5.63V18.371Z"
        fill={props.color}
      />
      <path
        d="M9.45 15.256C9.35441 15.256 9.25976 15.2371 9.17148 15.2005C9.0832 15.1638 9.00303 15.11 8.93558 15.0423C8.86812 14.9746 8.81471 14.8942 8.7784 14.8057C8.74209 14.7173 8.7236 14.6226 8.724 14.527V9.441C8.72347 9.34533 8.74186 9.25049 8.77811 9.16194C8.81436 9.0734 8.86775 8.99289 8.93522 8.92506C9.00269 8.85722 9.0829 8.80338 9.17124 8.76664C9.25959 8.72991 9.35432 8.711 9.45 8.711H14.55C14.6456 8.71114 14.7403 8.73013 14.8286 8.76691C14.9169 8.80369 14.997 8.85753 15.0645 8.92535C15.1319 8.99316 15.1853 9.07362 15.2216 9.1621C15.2579 9.25059 15.2764 9.34536 15.276 9.441V14.527C15.2763 14.6226 15.2577 14.7172 15.2213 14.8056C15.185 14.894 15.1316 14.9743 15.0641 15.042C14.9967 15.1097 14.9166 15.1635 14.8284 15.2002C14.7401 15.2369 14.6456 15.2559 14.55 15.256H9.45Z"
        fill={props.color}
      />
    </svg>
  );
};

export default SquareLogoIcon;
