const MarkerSelectedIcon = (props: any) => {
  return (
    <svg
      data-testid="marker-selected-icon"
      width="95"
      height="117"
      viewBox="0 0 95 117"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        cursor: props.onClick ? 'pointer' : 'default',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate3d(-50%, -50%, 0)',
        zIndex: props.zIndex ?? 1,
      }}
      onClick={props.onClick}
    >
      <g filter="url(#filter0_dd_101_2)">
        <path
          d="M46.4849 96.2438C47.0512 96.7905 47.9488 96.7905 48.5151 96.2438C63.4302 81.8439 70.6792 69.2534 74.1919 60.1906C75.9472 55.6618 76.7658 52.0236 77.144 49.4935C77.333 48.2288 77.4119 47.2418 77.443 46.5599C77.4585 46.2189 77.4621 45.9543 77.4615 45.7693C77.4612 45.6768 77.4599 45.6042 77.4585 45.552C77.4579 45.5315 77.4573 45.5142 77.4568 45.5C77.4408 28.9639 64.0242 15.5385 47.5 15.5385C30.9758 15.5385 17.5592 28.9639 17.5432 45.5C17.5427 45.5142 17.5421 45.5315 17.5416 45.552C17.5402 45.6042 17.5388 45.6768 17.5385 45.7693C17.5379 45.9543 17.5415 46.2189 17.557 46.5599C17.5881 47.2418 17.667 48.2288 17.856 49.4935C18.2342 52.0236 19.0528 55.6618 20.8081 60.1906C24.3208 69.2534 31.5698 81.8439 46.4849 96.2438Z"
          fill="white"
          stroke="white"
          strokeWidth="2.92308"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <mask
          id="mask0_101_2"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="19"
          y="17"
          width="57"
          height="57"
        >
          <path
            d="M76 45.5C76 29.7599 63.2401 17 47.5 17C31.7599 17 19 29.7599 19 45.5C19 61.2401 31.7599 74 47.5 74C63.2401 74 76 61.2401 76 45.5Z"
            fill="black"
          />
        </mask>
        <g mask="url(#mask0_101_2)">
          <rect x="19" y="17" width="57" height="57" fill="url(#pattern0)" />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_dd_101_2"
          x="0.0769043"
          y="0.0769043"
          width="94.8462"
          height="116.038"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="8" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_101_2"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_101_2"
            result="effect2_dropShadow_101_2"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_101_2"
            result="shape"
          />
        </filter>
        <pattern
          id="pattern0"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use xlinkHref="#image0_101_2" transform="scale(0.00333333)" />
        </pattern>
        <image
          id="image0_101_2"
          width="300"
          height="300"
          href={`${props.offer?.locations[0]?.logoUrl}`}
        />
      </defs>
    </svg>
  );
};

export default MarkerSelectedIcon;
