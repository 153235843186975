import React from 'react';

const CircleCheckIcon = ({
  fill = '#00B23B',
  width = 38,
  height = 38,
}: {
  fill?: string;
  width?: number;
  height?: number;
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 38 38"
      data-testid="circle-check-icon"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 19C0 8.50778 8.50778 0 19 0C29.4922 0 38 8.50778 38 19C38 29.4922 29.4922 38 19 38C8.50778 38 0 29.4922 0 19ZM4.22222 19C4.22222 27.1489 10.8511 33.7778 19 33.7778C27.1489 33.7778 33.7778 27.1489 33.7778 19C33.7778 10.8511 27.1489 4.22222 19 4.22222C10.8511 4.22222 4.22222 10.8511 4.22222 19ZM12.0545 16.4668L16.889 21.3012L25.9879 12.2445L28.9645 15.2212L18.409 25.7768C17.9868 26.1779 17.459 26.389 16.9101 26.389C16.3612 26.389 15.8334 26.199 15.4112 25.7768L9.07788 19.4434L12.0545 16.4668Z"
        fill={fill}
      />
    </svg>
  );
};

export default CircleCheckIcon;
