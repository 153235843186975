const 

MarkerIcon = (props: any) => {
  return (
    <svg
      data-testid="marker-icon"
      width={props.width || '32px'}
      height={props.height || '32px'}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        cursor: props.onClick ? 'pointer' : 'default',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate3d(-50%, -50%, 0)',
      }}
      onClick={props.onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.64641 14.7192C9.99827 13.3042 13 9.76338 13 6.22262C13 3.33825 10.7614 1 8 1C5.23858 1 3 3.33825 3 6.22262C3 9.76338 6.00173 13.3042 7.35359 14.7192C7.71107 15.0934 8.28893 15.0934 8.64641 14.7192ZM8 8.18106C9.10457 8.18106 10 7.26246 10 6.12931C10 4.99617 9.10457 4.07757 8 4.07757C6.89543 4.07757 6 4.99617 6 6.12931C6 7.26246 6.89543 8.18106 8 8.18106Z"
        fill={props.color || 'black'}
      />
    </svg>
  );
};

export default MarkerIcon;
