import React from 'react';

const CheckIcon = () => {
  return (
    <svg
      className="check-icon"
      data-testid="check-icon"
      width="19"
      height="12"
      viewBox="0 0 19 12"
      fill="none"
    >
      <path
        d="M7.50005 11.9998C7.76005 11.9998 8.01005 11.8998 8.21005 11.7098L18.21 1.7098L16.8 0.299805L7.50005 9.5898L2.21005 4.2998L0.800049 5.7098L6.80005 11.7098C6.99005 11.8998 7.24005 11.9998 7.50005 11.9998Z"
        fill="white"
      />
    </svg>
  );
};

export default CheckIcon;
