import React from 'react';

import { Card } from 'models/card';

import { Brands, SquareIssuedCards, cardBrands } from 'lib/constants';

import styles from './card-icon.module.css';

const CardImage = ({
  isDisabled,
  brand,
}: {
  isDisabled: boolean;
  brand: Brands;
}) => {
  const BrandInfo = cardBrands[brand] || cardBrands[Brands.UNKNOWN];
  return (
    <img
      className={isDisabled ? styles.disabled : ''}
      src={`/icons/card-icons/${BrandInfo.src}.svg`}
      alt={BrandInfo.alt}
      width={36}
      height={24}
      data-testid={BrandInfo.testId}
    />
  );
};

const CardIcon = ({
  card,
  disabled = false,
}: {
  card: Card;
  disabled?: boolean;
}) => {
  const isDisabled = disabled ? true : false;
  const brand = card.brand.toLocaleUpperCase();
  switch (brand) {
    case Brands.VISA: {
      return card.squareIssuedCard === SquareIssuedCards.CASH_CARD ? (
        <CardImage
          isDisabled={isDisabled}
          brand={Brands.SQUARE_CASH_APP_CARD}
        />
      ) : (
        <CardImage isDisabled={isDisabled} brand={brand} />
      );
    }
    case Brands.MASTERCARD: {
      return <CardImage isDisabled={isDisabled} brand={brand} />;
    }
    case Brands.AMERICAN_EXPRESS: {
      return <CardImage isDisabled={isDisabled} brand={brand} />;
    }
    case Brands.DISCOVER: {
      return <CardImage isDisabled={isDisabled} brand={brand} />;
    }
    case Brands.DISCOVER_DINERS: {
      return <CardImage isDisabled={isDisabled} brand={brand} />;
    }
    case Brands.JCB: {
      return <CardImage isDisabled={isDisabled} brand={brand} />;
    }
    case Brands.CHINA_UNIONPAY: {
      return <CardImage isDisabled={isDisabled} brand={brand} />;
    }
    default: {
      return <CardImage isDisabled={isDisabled} brand={Brands.UNKNOWN} />;
    }
  }
};

export default CardIcon;
