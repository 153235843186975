import React from 'react';

const BackIcon = ({ fill = '#000000' }: { fill?: string }) => {
  return (
    <svg
      data-testid="back-icon"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.29289 11.2929C3.90237 11.6834 3.90237 12.3166 4.29289 12.7071L11.2929 19.7071L12.7071 18.2929L7.41421 13L19 13L19 11L7.41421 11L12.7071 5.70712L11.2929 4.29291L4.29289 11.2929Z"
        fill={fill}
      />
    </svg>
  );
};

export default BackIcon;
