import React from 'react';

const PlusIcon = () => {
  return (
    <svg viewBox="0 0 40 40" data-testid="plus-icon" className="icon">
      <path
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeWidth="2"
        stroke="#71767B"
        d="m20,15l0,10m5,-5l-10,0"
      />
      <ellipse
        ry="18"
        rx="18"
        cy="20"
        cx="20"
        fillOpacity="0"
        strokeWidth="2"
        stroke="#F1F1F2"
        fill="#000000"
      />
    </svg>
  );
};

export default PlusIcon;
