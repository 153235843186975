import React from 'react';

const CircleUserIcon = () => {
  return (
    <svg
      className="circle-user-icon"
      data-testid="circle-user-icon"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_598_902)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 2C6.47714 2 2 6.47717 2 12C2 17.5228 6.47714 22 12 22C17.5229 22 22 17.5228 22 12C22 6.47717 17.5229 2 12 2ZM0 12C0 5.37256 5.37259 0 12 0C18.6274 0 24 5.37256 24 12C24 18.6274 18.6274 24 12 24C5.37259 24 0 18.6274 0 12ZM11.9 8C10.7955 8 9.90002 8.89545 9.90002 10C9.90002 11.1046 10.7955 12 11.9 12C13.0046 12 13.9 11.1046 13.9 10C13.9 8.89545 13.0046 8 11.9 8ZM7.90002 10C7.90002 7.79083 9.69089 6 11.9 6C14.1092 6 15.9 7.79083 15.9 10C15.9 11.0612 15.4868 12.0259 14.8124 12.7419C15.9698 13.3607 16.9488 14.3257 17.6533 15.4786C17.9413 15.9498 17.7927 16.5653 17.3214 16.8533C16.8502 17.1413 16.2347 16.9927 15.9467 16.5214C14.9878 14.9523 13.4803 14 11.9 14C10.3197 14 8.81223 14.9523 7.85327 16.5214C7.56531 16.9927 6.9498 17.1413 6.47855 16.8533C6.00729 16.5653 5.85873 15.9498 6.14673 15.4786C6.85126 14.3257 7.83017 13.3607 8.98758 12.7419C8.31326 12.0259 7.90002 11.0612 7.90002 10Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_598_902">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CircleUserIcon;
