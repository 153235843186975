import React from 'react';
import { BonusTypes } from 'lib/constants';
import styles from './bonus-pill-icon.module.css';

const BonusIconImage = ({
  bonusPillImage,
}: {
  bonusPillImage: string;
}) => {
  return (
    <img
      className={styles.bonusIcon}
      src={`/icons/${bonusPillImage}.svg`}
      alt={bonusPillImage}
      width={16}
      height={16}
      data-testid={bonusPillImage}
    />
  );
};

const BonusPillIcon = ({
  bonusType,
}: {
  bonusType: BonusTypes;
}) => {
  switch (bonusType) {
    case BonusTypes.CashAppCardBonus: {
      return <BonusIconImage bonusPillImage='cashapp' />
    }
    case BonusTypes.WinbackInactiveEarnerBonus: {
      return <BonusIconImage bonusPillImage='money' />
    }
    case BonusTypes.WinbackInactiveUserBonus: {
      return <BonusIconImage bonusPillImage='money' />
    }
    case BonusTypes.TransactionBonus: {
      return <BonusIconImage bonusPillImage='money' />
    }
    default: {
      return <BonusIconImage bonusPillImage='money' />;
    }
  }
};

export default BonusPillIcon;
