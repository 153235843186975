import React from 'react';

const ClockIcon = () => {
  return (
    <svg
      className="clock-icon"
      width="17"
      height="16"
      data-testid="clock-icon"
      viewBox="0 0 17 16"
      fill="none"
    >
      <path
        d="M8.5 4.99982V7.99998L10 9.49982M15.5 8C15.5 11.866 12.366 15 8.5 15C4.63401 15 1.5 11.866 1.5 8C1.5 4.13401 4.63401 1 8.5 1C12.366 1 15.5 4.13401 15.5 8Z"
        stroke="black"
        strokeOpacity="0.3"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ClockIcon;
