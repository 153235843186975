import React from 'react';

const ArrowClockIcon = ({ width = 60, height = 60 }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width, height }}
      data-testid="arrow-clock-icon"
    >
      <rect width="40" height="40" rx="20" fill="#FFE5EA" />
      <path
        d="M19.9972 13C16.1312 13 12.9972 16.134 12.9972 20V20.5887L14.293 19.293L15.7072 20.7072L12.7072 23.7072C12.3167 24.0977 11.6835 24.0977 11.293 23.7072L8.29297 20.7072L9.70718 19.293L10.9972 20.583V20C10.9972 15.0294 15.0266 11 19.9972 11C24.9678 11 28.9972 15.0294 28.9972 20C28.9972 24.9706 24.9678 29 19.9972 29C17.3108 29 14.8983 27.8217 13.2507 25.9571L14.7495 24.6328C16.0335 26.086 17.908 27 19.9972 27C23.8632 27 26.9972 23.866 26.9972 20C26.9972 16.134 23.8632 13 19.9972 13Z"
        fill="#CC0023"
      />
      <path
        d="M19.0001 20V15H21.0001V19.5858L23.7043 22.29L22.2901 23.7042L19.293 20.7071C19.1055 20.5196 19.0001 20.2652 19.0001 20Z"
        fill="#CC0023"
      />
    </svg>
  );
};

export default ArrowClockIcon;
