import React from 'react';

const LockOff = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="51"
      height="51"
      viewBox="0 0 51 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1555_8800)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.875 18.8923L33.5429 18.8797C37.0213 18.8768 39.8316 21.6822 39.8346 25.1606L39.8471 39.8286C39.8501 43.307 37.0446 46.1173 33.5662 46.1202L16.8028 46.1345C13.3244 46.1375 10.5141 43.332 10.5112 39.8536L10.4987 25.1857C10.4963 22.4616 12.2545 20.1552 14.6845 19.273L14.6806 14.705C14.6756 8.92162 19.3654 4.22386 25.1487 4.21893C28.0404 4.21646 30.6607 5.38766 32.5482 7.29288L29.5962 10.25C28.4637 9.11939 26.8915 8.40829 25.1523 8.40977C21.6949 8.41272 18.8685 11.244 18.8714 14.7014L18.875 18.8923ZM33.5626 41.9294C34.7151 41.9284 35.6572 40.9847 35.6563 39.8322L35.6437 25.1642C35.6428 24.0117 34.699 23.0696 33.5465 23.0706L16.7831 23.0849C15.6307 23.0859 14.6885 24.0296 14.6895 25.1821L14.702 39.8501C14.703 41.0025 15.6468 41.9447 16.7992 41.9437L33.5626 41.9294ZM28.316 32.5045C28.3175 34.2404 26.9115 35.6488 25.1756 35.6503C23.4397 35.6518 22.0312 34.2457 22.0297 32.5098C22.0283 30.7739 23.4343 29.3655 25.1702 29.364C26.9061 29.3625 28.3145 30.7685 28.316 32.5045Z"
          fill="#EDFF80"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29.5962 10.2503C28.4637 9.11976 26.8915 8.40866 25.1523 8.41014C21.6949 8.41309 18.8685 11.2443 18.8714 14.7018L18.875 18.8926L33.5429 18.8801C37.0213 18.8771 39.8316 21.6826 39.8346 25.161L39.8471 39.829C39.8501 43.3074 37.0446 46.1176 33.5662 46.1206L16.8028 46.1349C13.3244 46.1379 10.5141 43.3324 10.5112 39.854L10.4987 25.186C10.4963 22.462 12.2545 20.1555 14.6845 19.2734L14.6806 14.7054C14.6756 8.92198 19.3654 4.22423 25.1487 4.21929C26.9499 4.21776 28.6458 4.67157 30.1248 5.4745C30.9864 5.94222 31.7744 6.5284 32.4668 7.21204C32.4941 7.23896 32.5212 7.26603 32.5482 7.29325L29.5962 10.2503ZM21.8724 15.8901L33.5404 15.8801C38.6756 15.8757 42.8302 20.0232 42.8346 25.1584L42.8471 39.8264C42.8515 44.9617 38.704 49.1162 33.5688 49.1206L16.8054 49.1349C11.6701 49.1393 7.51556 44.9918 7.51118 39.8566L7.49866 25.1886C7.49587 21.929 9.18295 19.1036 11.6829 17.4497L11.6806 14.7079C11.6742 7.26769 17.7059 1.22565 25.1462 1.21929C28.8657 1.21612 32.2468 2.72638 34.6794 5.18186C35.8409 6.3543 35.8373 8.24477 34.6713 9.41276L31.7193 12.3698C31.1572 12.9329 30.3944 13.2496 29.5987 13.2503C28.8031 13.251 28.0398 12.9356 27.4767 12.3735C26.8786 11.7764 26.0588 11.4094 25.1549 11.4101C23.3543 11.4117 21.8699 12.8986 21.8714 14.6992L21.8724 15.8901ZM35.6562 39.8325C35.6572 40.985 34.7151 41.9288 33.5626 41.9298L16.7992 41.9441C15.6467 41.945 14.703 41.0029 14.702 39.8504L14.6895 25.1825C14.6885 24.03 15.6307 23.0862 16.7831 23.0853L33.5465 23.0709C34.699 23.07 35.6427 24.0121 35.6437 25.1646L35.6562 39.8325Z"
          fill="#EDFF80"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.875 18.8923L33.5429 18.8797C37.0213 18.8768 39.8316 21.6822 39.8346 25.1606L39.8471 39.8286C39.8501 43.307 37.0446 46.1173 33.5662 46.1202L16.8028 46.1345C13.3244 46.1375 10.5141 43.332 10.5112 39.8536L10.4987 25.1857C10.4963 22.4616 12.2545 20.1552 14.6845 19.273L14.6806 14.705C14.6756 8.92162 19.3654 4.22386 25.1487 4.21893C28.0404 4.21646 30.6607 5.38766 32.5482 7.29288L29.5962 10.25C28.4637 9.11939 26.8915 8.40829 25.1523 8.40977C21.6949 8.41272 18.8685 11.244 18.8714 14.7014L18.875 18.8923ZM33.5626 41.9294C34.7151 41.9284 35.6572 40.9847 35.6563 39.8322L35.6437 25.1642C35.6427 24.0117 34.699 23.0696 33.5465 23.0706L16.7831 23.0849C15.6307 23.0859 14.6885 24.0296 14.6895 25.1821L14.702 39.8501C14.703 41.0025 15.6468 41.9447 16.7992 41.9437L33.5626 41.9294ZM28.316 32.5045C28.3175 34.2404 26.9115 35.6488 25.1756 35.6503C23.4397 35.6518 22.0312 34.2457 22.0297 32.5098C22.0283 30.7739 23.4343 29.3655 25.1702 29.364C26.9061 29.3625 28.3145 30.7685 28.316 32.5045Z"
          fill="#005A44"
        />
      </g>
      <defs>
        <clipPath id="clip0_1555_8800">
          <rect
            width="50.2902"
            height="50.2902"
            fill="white"
            transform="translate(0 0.0429688) rotate(-0.0489038)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LockOff;
