import { Money } from 'models/money';

export const enum RedemptionStatus {
  Posted = 'POSTED',
  Failed = 'FAILED',
  Pending = 'PENDING',
  Reversed = 'REVERSED',
}

export type Redemption = {
  id: string;
  status: RedemptionStatus;
  cashtag: string;
  details: string;
  amount: Money;
  createdAt: string;
  updatedAt: string;
};
