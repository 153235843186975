import { RedemptionStatus } from 'models/redemption';
import React from 'react';

const RedemptionIcon = ({
  width = 60,
  height = 60,
  status = RedemptionStatus.Pending,
}) => {
  switch (status) {
    default:
      return (
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ width, height }}
          data-testid="default-redemption-icon"
        >
          <rect width="40" height="40" rx="20" fill="#FFF2E5" />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M28.71 15.7098L24.71 19.7098L23.3 18.2898L25.59 15.9998H12V13.9998H25.59L23.3 11.7098L24.71 10.2998L28.71 14.2998C29.1 14.6898 29.1 15.3198 28.71 15.7098ZM16.7001 28.2998L15.2901 29.7098L11.2901 25.7098C10.9001 25.3198 10.9001 24.6898 11.2901 24.2998L15.2901 20.2998L16.7001 21.7098L14.4101 23.9998H28.0001V25.9998H14.4101L16.7001 28.2998Z"
            fill="#FF9F40"
          />
        </svg>
      );
    case RedemptionStatus.Pending:
      return (
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ width, height }}
          data-testid="pending-redemption-icon"
        >
          <rect width="40" height="40" rx="20" fill="#FFF2E5" />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M28.71 15.7098L24.71 19.7098L23.3 18.2898L25.59 15.9998H12V13.9998H25.59L23.3 11.7098L24.71 10.2998L28.71 14.2998C29.1 14.6898 29.1 15.3198 28.71 15.7098ZM16.7001 28.2998L15.2901 29.7098L11.2901 25.7098C10.9001 25.3198 10.9001 24.6898 11.2901 24.2998L15.2901 20.2998L16.7001 21.7098L14.4101 23.9998H28.0001V25.9998H14.4101L16.7001 28.2998Z"
            fill="#FF9F40"
          />
        </svg>
      );
    case RedemptionStatus.Failed:
      return (
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ width, height }}
          data-testid="failed-redemption-icon"
        >
          <rect width="40" height="40" rx="20" fill="#FFE5EA" />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M28.71 15.7098L24.71 19.7098L23.3 18.2898L25.59 15.9998H12V13.9998H25.59L23.3 11.7098L24.71 10.2998L28.71 14.2998C29.1 14.6898 29.1 15.3198 28.71 15.7098ZM16.7001 28.2998L15.2901 29.7098L11.2901 25.7098C10.9001 25.3198 10.9001 24.6898 11.2901 24.2998L15.2901 20.2998L16.7001 21.7098L14.4101 23.9998H28.0001V25.9998H14.4101L16.7001 28.2998Z"
            fill="#CC0023"
          />
        </svg>
      );
    case RedemptionStatus.Posted:
      return (
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ width, height }}
          data-testid="posted-redemption-icon"
          >
          <rect width="40" height="40" rx="20" fill="#CCFFDD" />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12.8 11H27.2C28.1941 11 29 11.8059 29 12.8V27.2C29 28.1941 28.1941 29 27.2 29H12.8C11.8059 29 11 28.1941 11 27.2V12.8C11 11.8059 11.8059 11 12.8 11ZM22.514 17.9134C21.9488 17.4079 21.0838 17.0397 20.1814 17.0397C19.4751 17.0397 18.7683 17.2944 18.7683 17.9384C18.7683 18.5485 19.4136 18.783 20.2009 19.069C20.2665 19.0928 20.333 19.117 20.4003 19.1418C21.9312 19.6673 23.1926 20.3116 23.1926 21.8371C23.1926 23.4952 21.9268 24.628 19.8586 24.7566L19.6707 25.6579C19.6366 25.8233 19.4911 25.9413 19.3237 25.94L18.0222 25.9301C17.7989 25.9282 17.6338 25.721 17.6801 25.5012L17.8783 24.5637C17.0642 24.3402 16.351 23.9413 15.7876 23.3902C15.6452 23.2512 15.6439 23.0204 15.7853 22.8801L16.5078 22.1618C16.6448 22.0256 16.8639 22.0267 17.0009 22.1629C17.6992 22.8566 18.599 23.1423 19.4581 23.1423C20.4003 23.1423 21.0394 22.7525 21.0394 22.0913C21.0394 21.5086 20.5163 21.3141 19.5197 20.9436C19.4147 20.9045 19.3045 20.8635 19.189 20.8199C17.9106 20.3624 16.6993 19.7012 16.6993 18.1585C16.6993 16.3764 18.1633 15.5079 19.8907 15.421L20.0806 14.521C20.1152 14.3572 20.2589 14.24 20.4251 14.24H21.7245C21.9483 14.24 22.115 14.4473 22.0692 14.6678L21.8584 15.6798C22.6039 15.9259 23.2097 16.3103 23.6609 16.7012C23.8142 16.8337 23.8193 17.0712 23.6774 17.2163L23.005 17.9035C22.8709 18.0405 22.6569 18.0413 22.514 17.9134Z"
            fill="#00B23B"
          />
        </svg>
      );
  }
};

export default RedemptionIcon;
