import { ValidRegion } from 'lib/constants';

const RegionIcon = ({
  region,
  selected = false,
}: {
  region?: ValidRegion;
  selected?: boolean;
}) => {
  const color = selected ? '#006AFF' : '#000000';

  switch (region) {
    case ValidRegion.portland: {
      return (
        <svg
          data-testid="region-icon"
          width="31"
          height="23"
          viewBox="0 0 31 23"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M27.7504 21.0791C27.7964 21.0791 27.8376 21.0391 27.8387 20.9925L28.0235 11.5744C28.0256 11.4543 27.9162 11.2666 27.81 11.2098L27.5585 11.075C27.131 10.8457 26.9207 10.3085 27.079 9.85078L28.923 4.52361C28.9647 4.40297 28.9203 4.17251 28.8368 4.07621L27.7682 2.83734C27.6702 2.72373 27.422 2.61174 27.267 2.61174L21.0721 2.74591C20.8315 2.75078 20.4165 2.81245 20.1845 2.87791L14.3842 4.50847C14.0769 4.59448 13.58 4.65129 13.256 4.63398L11.4417 4.53876C11.2087 4.52686 10.8267 4.58204 10.617 4.65345L10.2729 4.77139C10.0664 4.84226 9.84426 4.85687 9.69416 4.85687C9.45085 4.85687 9.21622 4.81846 9.03468 4.74867L8.5291 4.55445C8.15304 4.41001 7.77588 4.01238 7.65125 3.62989L7.20148 2.24333C7.16843 2.14216 7.01616 2.02206 6.90995 2.01394L6.83137 2.00799C6.49215 1.98148 6.03968 1.80891 5.81967 1.52867C5.798 1.51731 5.74435 1.50054 5.6712 1.5C5.63164 1.5 5.59262 1.50487 5.55794 1.51461L4.83127 1.7164C4.73102 1.74453 4.60802 1.88952 4.59718 1.99339L3.50528 12.2712C3.47276 12.5779 3.37089 13.0475 3.27335 13.3407L2.03135 17.0768C1.9777 17.2391 1.99504 17.5502 2.06711 17.7049L2.11534 17.8088C2.25244 18.1047 2.38032 18.59 2.40633 18.9135L2.54776 20.6625C2.55264 20.7226 2.61875 20.7843 2.67944 20.7848L27.7504 21.0791ZM27.7504 21.0791V21.5"
            stroke={color}
            strokeWidth="3"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
        </svg>
      );
    }
    case ValidRegion.atlanta: {
      return (
        <svg
          data-testid="region-icon"
          width="24"
          height="27"
          viewBox="0 0 24 27"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21.9881 17.1291L21.344 18.0742C21.1905 18.3 21.0141 18.6829 20.9427 18.9457L19.752 23.3352L18.8155 22.8787C18.6543 22.8001 18.4458 22.7571 18.2282 22.7571C18.1036 22.7571 17.9185 22.7717 17.7449 22.8421L17.6322 22.8873C17.262 23.0377 17.0201 23.4494 17.07 23.8448L17.2482 25.2596C17.2496 25.2683 17.2496 25.2797 17.2432 25.2871C17.2372 25.2939 17.2253 25.2957 17.2171 25.2962L17.158 25.298C17.0778 25.298 16.9647 25.2194 16.9381 25.1454L16.8355 24.8543C16.7095 24.4983 16.3292 24.2131 15.9512 24.1907L5.06953 23.5527C4.96095 23.5463 4.79419 23.4376 4.74471 23.3411L3.91136 21.7115C3.84813 21.5872 3.82981 21.3358 3.87516 21.2037L4.34246 19.8359C4.44829 19.5252 4.4176 19.07 4.27054 18.7771L3.96129 18.1606C3.90586 18.05 3.9054 17.8242 3.96038 17.7132L4.95133 15.7129C5.12084 15.3706 5.05395 14.9003 4.79556 14.6184L4.12347 13.8853C4.01215 13.7642 3.88249 13.496 3.85638 13.3333L2.00183 1.74739C2.00092 1.74236 2.00092 1.73917 2 1.73734C2.00229 1.73642 2.00596 1.73597 2.011 1.73597L11.8074 1.70215L11.4019 2.45346C11.2255 2.78021 11.259 3.25275 11.4794 3.55163L11.5728 3.67822C11.7639 3.93825 12.1473 4.18046 12.4644 4.24216L12.9422 4.33539C13.0696 4.36007 13.2638 4.50082 13.327 4.61416L14.6501 6.98599C14.7825 7.22318 15.0432 7.55267 15.2434 7.73593L19.0254 11.1986C19.1353 11.2992 19.2608 11.5432 19.2787 11.6913L19.5087 13.5805C19.5522 13.9365 19.8326 14.314 20.162 14.4584L20.7924 14.7349C20.8849 14.7756 20.988 14.9246 20.9935 15.0256L21.0338 15.8089C21.0517 16.1516 21.2789 16.5565 21.563 16.7507L21.9694 17.0281C22.001 17.0501 22.0097 17.0971 21.9881 17.1291Z"
            stroke={color}
            strokeWidth="3"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
        </svg>
      );
    }
    default: {
      return (
        <svg
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 12.5C8 13.6046 7.10457 14.5 6 14.5C4.89543 14.5 4 13.6046 4 12.5C4 11.3954 4.89543 10.5 6 10.5C7.10457 10.5 8 11.3954 8 12.5ZM12 14.5C13.1046 14.5 14 13.6046 14 12.5C14 11.3954 13.1046 10.5 12 10.5C10.8954 10.5 10 11.3954 10 12.5C10 13.6046 10.8954 14.5 12 14.5ZM18 14.5C19.1046 14.5 20 13.6046 20 12.5C20 11.3954 19.1046 10.5 18 10.5C16.8954 10.5 16 11.3954 16 12.5C16 13.6046 16.8954 14.5 18 14.5Z"
            fill="black"
            fillOpacity="0.3"
          />
        </svg>
      );
    }
  }
};

export default RegionIcon;
